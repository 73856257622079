export default {
  "placeholder.username": 'Please enter account',
  "placeholder.password": 'Please enter password',
  "placeholder.captcha": 'Please enter verification code',
  "btn.signin": 'Sign in',
  "title.login": 'Welcome to',
  "tip.login.success": 'Sign in successfully',
  "btn.forgotten.password": 'Forget the password?',
  'captcha.title': '<em>Select in</em> this order:',
  'btn.ok': 'Check'
}
