/**
 * Api for account login
 **/

import axios, { AxiosResponse} from 'axios';

console.log("REACT_APP_CAPTCHA_SERVICE_URL: ", process.env.REACT_APP_CAPTCHA_SERVICE_URL);
console.log("REACT_APP_API_HOST: ", process.env.REACT_APP_API_HOST);

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
axios.defaults.timeout = 60 * 1000 // 1 minute

const captchatAxios = axios.create({
  baseURL: process.env.REACT_APP_CAPTCHA_SERVICE_URL,
  timeout: 60 * 1000
})


type Response<D> = {
  code: number,
  message: string,
  data: D
}

function post<D>(url: string, body?: Record<string, any>) {
  return axios.post<any, AxiosResponse<Response<D>, any>>(url, body);
}

function get<D>(url: string, params?: Record<string, any>) {
  return axios.get<any,AxiosResponse<Response<D>, any>>(url, { params });
}

export type TCaptcha = {
  captchaId: string,
  captcha: string
}

export function getCaptcha() {

  const url = 'sso-service/api/v1/getCaptcha';
  return captchatAxios.get<any, AxiosResponse<Response<TCaptcha>>>(url);
}


export type TLoginUrlData = {
  url: string;
  captcha_url: string;
}

export function fetchLoginUrl( account: string) {
  const url = '/app-service/api/v1/user/login_url'
  return post<TLoginUrlData>(url, { account })
}


export type TLoginData = {
  token: string;
  expires: number;
  is_sys_password: boolean; 
  account_type: number;
}


type LoginParams = {
  account: string,
  password: string,
  appid: string,
}

export function doLogin(loginUrl: string, data: LoginParams) {
  const payload = { ...data, timestamp: Date.now(), captcha: void 0, captchaId: void 0 }
  return post<TLoginData>(loginUrl, payload);
}



export type TokenByCodeData = {
  token: string,
  expires: number
}

export function fetchTokenByCode(code: string, appId: string) {
  // 用于区分是企业微信扫码登陆还是企业微信自动登陆，app_inner-自动登陆，scan_code-扫码登陆
  const state: 'app_inner' | 'scan_code' = 'scan_code';
  
  const url = `/app-service/api/v1/enterprise_wechat/callback?code=${encodeURIComponent(code)}&state=${state}&appid=${appId}`
  return get<TokenByCodeData>(url)
}

export type ClickCaptchaData = {
  timestamp: string,
  captcha_id: string,
  thumb_base64: string,
  image_base64: string
}

export function  fetchClickCaptcha(captchaUrl: string) {
  const request = axios.create({
    baseURL: captchaUrl,
    timeout: 60 * 1000,        // 1 min
  })
  
  const url = ''
  return request<any, AxiosResponse<Response<ClickCaptchaData>>>({
    method: 'post',
    url,
    data: {}
  }).then(resp => resp.data)
}


export type LoginCaptchaParams = {
  appid: string,
  account: string,
  password: string,
  timestamp: string,
  captcha: string,
  captcha_id: string
}

export type LoginCaptchaData = {
  token: string,                // string
  expires: number,              // 过期时间
  is_sys_password: boolean,     // 是否系统密码
  account_type: number          //  账户类型
}

export function loginWithCaptcha(loginUrl: string, param: LoginCaptchaParams) {
  const request = axios.create({ baseURL: loginUrl, timeout: 60 * 1000 })
  const url = ''
  return request<any, AxiosResponse<Response<LoginCaptchaData>>>({
    method: 'post',
    url,
    data: param
  }).then(resp => resp.data)
}





