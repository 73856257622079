import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './view/App';
import ErrorPage from './view/error-page';
import Redirect from './view/redirect';
import Success from './view/success';


// app router
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />
  },
  {
    path: '/redirect',
    element: <Redirect />,
    errorElement: <ErrorPage />
  },
  {
    path: '/success',
    element: <Success />,
    errorElement: <ErrorPage />
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Toaster position='top-center' />
    <RouterProvider router={router} />
  </React.StrictMode>
);
