import { type FC, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as ww from "@wecom/jssdk"
import Container from './container';
import { getCurrentLocale } from '../i18n';
import useQuery from '../hooks/use-query';
import { fetchTokenByCode } from '../api/api';
import { Messenger } from '../common/messenger';


export interface WeWorkLoginProps {
  title: string;
}

const WeWorkLogin: FC<WeWorkLoginProps> = ({ title }) => {
  const navigate = useNavigate();
  const query = useQuery();
  const redirectType: 'callback' | 'self' | string | null = query.get('redirect_type');

  const doFetchTokenByCode = useCallback((code: string, appId: string) => {
    fetchTokenByCode(code, appId).then(response => {
      const responseData = response.data;
    
      if (responseData.code === 200) {
        const { token, expires } = responseData.data;
        const _token = encodeURIComponent(token);
        const path = `/success?appid=${appId}&token=${_token}&expires=${expires}`
        navigate(path);
        Messenger.getInstance().notifyLoginSuccess(token, expires);
      } else {
        toast.error(responseData.message);
      }
    })  
  }, [navigate])

  useEffect(() => {
    if (redirectType !== 'callback') {
      // 注入全局的函数
      window.onCodeReceived = function (data: { code: string, platformId: string }) {
        doFetchTokenByCode(data.code, data.platformId)
      }
    }
  }, [redirectType, doFetchTokenByCode])
  
  useEffect(() => {
    const host = window.location.host;
    const protocol = window.location.protocol; // e.g.: "https:"

    const getAndPersistNonce: () => string = () => {
      const key = 'pudu-onelogin-csrf'

      let existedNonce: string | null = sessionStorage.getItem(key);
      if (!existedNonce) {
        existedNonce = '' + Date.now();
        sessionStorage.setItem(key, existedNonce);
      }

      return existedNonce;
    };

    
    const appId = query.get('appid') ?? ''; //  as platformId
    const rediretUri = process.env.REACT_APP_WXWORK_REDIRECT_URI as string + `?platformid=${appId}`

    const wxLogin = ww.createWWLoginPanel({
      el: '#pudu-onelogin-qrcode',
      params: {
        login_type: ww.WWLoginType.corpApp,
        appid: process.env.REACT_APP_WXWORK_APPID as string,
        agentid: process.env.REACT_APP_WXWORK_AGENTID,
        redirect_uri: rediretUri,
        redirect_type: redirectType === 'callback' ? ww.WWLoginRedirectType.callback : ww.WWLoginRedirectType.self,
        state: getAndPersistNonce(), // 用于防止csrf攻击
        lang: getCurrentLocale().slice(0, 2) === 'en' ? ww.WWLoginLangType.en : ww.WWLoginLangType.zh,
        panel_size: ww.WWLoginPanelSizeType.middle,
        href: `${protocol}//${host}/css/wxwork-qrcode.css`, // 自定义样式链接，企业根据实际需求覆盖默认样式
      },

      onCheckWeComLogin({ isWeComLogin }) {
        console.log(isWeComLogin)
      },
      onLoginSuccess({ code }) {
        console.log({ code })
        doFetchTokenByCode(code, appId);
      },
      onLoginFail(err) {
        console.log(err)
      },
    })

    const iframeEle: HTMLIFrameElement | null = document.querySelector('#pudu-onelogin-qrcode > iframe');
    iframeEle?.setAttribute('height', '460');

    return () => {
      // wxLogin.destroyed();
      wxLogin.unmount();
    };
  }, [])

  return (
    <Container title={title}>
      <div id="pudu-onelogin-qrcode" style={{ display: 'flex', flex: 'row', justifyContent: 'center', marginTop: '-13.91vh'}}>
      </div>
    </Container>
  )
}

export default WeWorkLogin;
