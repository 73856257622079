export default {
  "placeholder.username": '请输入账户',
  "placeholder.password": '请输入密码',
  "placeholder.captcha": '请输入验证码',
  "btn.signin": '登录',
  "title.login": '欢迎登录',
  "tip.login.success": '登录成功',
  "btn.forgotten.password": '忘记密码？',
  'captcha.title': '请<em>依次</em>点击：',
  'btn.ok': '确定'
}
