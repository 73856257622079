/**
 * Click captcha
 */
import { useEffect, useState, Fragment, MouseEventHandler, type FC } from 'react'
import { formatMessage, getHTML } from 'react-intl-universal';
import { fetchClickCaptcha } from '../api/api'
import "./click-captcha.css"

type Vector = { x: number, y: number, index: number }
export interface ClickCaptchaProps {
  onClose: () => void;
  onConfirm: (data: { dots: Vector[], timestamp: string, captchaId: string }) => void,
  maxDot: number,
  width: number,
  height: number,
  captchaUrl: string,
}

function renderDotItem(dots: Vector[]) {
  return (
    <>
      {
        dots.map((dot, index) => (
          <div key={index} className="wg-cap-wrap__dot" style={{top: `${dot.y}px`, left: `${dot.x}px`}}>
            <span>{index+1}</span>
          </div>
        ))
      }
    </>    
  )
}

const ClickCaptcha: FC<ClickCaptchaProps> = (props: ClickCaptchaProps) => {
  const {
    width = 300,
    height = 240,
    maxDot,
    onConfirm,
    captchaUrl,
    onClose
  } = props
  
  const [dots, setDots] = useState<Vector[]>([])
  const [imageBase64, setImageBase64] = useState<string>()
  const [thumbBase64, setThumbBase64] = useState<string>()
  const [timestamp, setTimestamp] = useState("")
  const [captchaId, setCaptchaId] = useState("")


  useEffect(() => {
    fetchClickCaptcha(captchaUrl).then(resp => {
      console.log("resp: ", resp)
      if (resp.code === 200) {
        const { image_base64, thumb_base64, captcha_id, timestamp } = resp.data
        setImageBase64(image_base64)
        setThumbBase64(thumb_base64)
        setTimestamp(timestamp)
        setCaptchaId(captcha_id)
      }
    })

  }, [])

  // ================= Methods ================
  /**
   * @Description: 处理关闭事件
   */
  function handleCloseEvent () {
    setDots([])
    onClose()
  }

  /**
   * @Description: 处理刷新事件
   */
  function handleRefreshEvent() {
    fetchClickCaptcha(captchaUrl).then(resp => {
      console.log("resp: ", resp)
      if (resp.code === 200) {
        const { image_base64, thumb_base64, captcha_id, timestamp } = resp.data
        setImageBase64(image_base64)
        setThumbBase64(thumb_base64)
        setTimestamp(timestamp)
        setCaptchaId(captcha_id)

        setDots([])
      }
    })
  }

  /**
   * @Description: 处理确认事件
   */
  function handleConfirmEvent() {
    onConfirm({ dots, timestamp, captchaId })
  }

  /**
   * @Description: 处理dot
   * @param ev
   */
  const handleClickPos: MouseEventHandler<HTMLImageElement> = (ev) => {

    if (dots.length >= maxDot) {
      return
    }
    
    const e = ev || window.event
    e.preventDefault()
    const dom = e.currentTarget
    if (!dom) {
      return
    }
    
    const {domX, domY} = getDomXY(dom)
    // ===============================================
    // @notice 如 getDomXY 不准确可尝试使用 calcLocationLeft 或 calcLocationTop
    // const domX = this.calcLocationLeft(dom)
    // const domY = this.calcLocationTop(dom)
    // ===============================================

    // @ts-ignore
    let mouseX = (navigator.appName === 'Netscape') ? e.pageX : e.x + document.body.offsetTop
    // @ts-ignore
    let mouseY = (navigator.appName === 'Netscape') ? e.pageY : e.y + document.body.offsetTop

    // 计算点击的相对位置
    const xPos = mouseX - domX
    const yPos = mouseY - domY

    // 转整形
    const xp = parseInt(xPos.toString())
    const yp = parseInt(yPos.toString())

    // 减去点的一半
    const newDots = [...dots, {
      x: xp - 11,
      y: yp - 11,
      index: dots.length + 1
    }]

    setDots(newDots);

    return false
  }

  
  /**
   * @Description: 找到元素的屏幕位置
   * @param dom
   */
  function getDomXY(dom: HTMLElement) {
    let _dom: HTMLElement | null = dom;
    if (!_dom) {
      throw new Error("dom cant be null")
    }
    
    let x = 0
    let y = 0
    if (_dom.getBoundingClientRect) {
      let box = _dom.getBoundingClientRect();
      let D = document.documentElement;
      x = box.left + Math.max(D.scrollLeft, document.body.scrollLeft) - D.clientLeft;
      y = box.top + Math.max(D.scrollTop, document.body.scrollTop) - D.clientTop
    } else {
      while (_dom && _dom !== document.body) {
        x += _dom!.offsetLeft
        y += _dom!.offsetTop
        _dom = _dom!.offsetParent as HTMLElement
      }
    }
    return {
      domX: x,
      domY: y
    }
  }

  return (
    <Fragment>
      <div className="wg-cap-container">
        <div className="wg-cap-wrap">
          <div className="wg-cap-wrap__header">
            <span>{getHTML('captcha.title')}</span>
            {
              thumbBase64 && <img className="wg-cap-wrap__thumb" src={thumbBase64} alt=" "/>
            }
        </div>
        <div
          className="wg-cap-wrap__body"
          style={{
            width: width,
            height: height
          }}>
          {
            imageBase64 && <img className="wg-cap-wrap__picture"
                             src={imageBase64} alt=" "
                             onClick={handleClickPos}/>
          }
          <img className="wg-cap-wrap__loading"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBzdHlsZT0ibWFyZ2luOiBhdXRvOyBiYWNrZ3JvdW5kOiByZ2JhKDI0MSwgMjQyLCAyNDMsIDApOyBkaXNwbGF5OiBibG9jazsgc2hhcGUtcmVuZGVyaW5nOiBhdXRvOyIgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIj4KICA8Y2lyY2xlIGN4PSI1MCIgY3k9IjM2LjgxMDEiIHI9IjEzIiBmaWxsPSIjM2U3Y2ZmIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN5IiBkdXI9IjFzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgY2FsY01vZGU9InNwbGluZSIga2V5U3BsaW5lcz0iMC40NSAwIDAuOSAwLjU1OzAgMC40NSAwLjU1IDAuOSIga2V5VGltZXM9IjA7MC41OzEiIHZhbHVlcz0iMjM7Nzc7MjMiPjwvYW5pbWF0ZT4KICA8L2NpcmNsZT4KPC9zdmc+"
            alt="正在加载中..."/>
          { renderDotItem(dots) }
        </div>
        <div className="wg-cap-wrap__footer">
          <div className="wg-cap-wrap__ico">
            <img onClick={handleCloseEvent}
              src="data:image/svg+xml;base64,PHN2ZyB0PSIxNjI2NjE0NDM5NDIzIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9Ijg2NzUiIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIj48cGF0aCBkPSJNNTEyIDIzLjI3MjcyN2MyNjkuOTE3MDkxIDAgNDg4LjcyNzI3MyAyMTguODEwMTgyIDQ4OC43MjcyNzMgNDg4LjcyNzI3M2E0ODYuNjMyNzI3IDQ4Ni42MzI3MjcgMCAwIDEtODQuOTQ1NDU1IDI3NS40MDk0NTUgNDYuNTQ1NDU1IDQ2LjU0NTQ1NSAwIDAgMS03Ni44NDY1NDUtNTIuNTI2NTQ2QTM5My41NDE4MTggMzkzLjU0MTgxOCAwIDAgMCA5MDcuNjM2MzY0IDUxMmMwLTIxOC41MDc2MzYtMTc3LjEyODcyNy0zOTUuNjM2MzY0LTM5NS42MzYzNjQtMzk1LjYzNjM2NFMxMTYuMzYzNjM2IDI5My40OTIzNjQgMTE2LjM2MzYzNiA1MTJzMTc3LjEyODcyNyAzOTUuNjM2MzY0IDM5NS42MzYzNjQgMzk1LjYzNjM2NGEzOTUuMTcwOTA5IDM5NS4xNzA5MDkgMCAwIDAgMTI1LjQ0LTIwLjI5MzgxOSA0Ni41NDU0NTUgNDYuNTQ1NDU1IDAgMCAxIDI5LjQ4NjU0NSA4OC4yOTY3MjhBNDg4LjI2MTgxOCA0ODguMjYxODE4IDAgMCAxIDUxMiAxMDAwLjcyNzI3M0MyNDIuMDgyOTA5IDEwMDAuNzI3MjczIDIzLjI3MjcyNyA3ODEuOTE3MDkxIDIzLjI3MjcyNyA1MTJTMjQyLjA4MjkwOSAyMy4yNzI3MjcgNTEyIDIzLjI3MjcyN3ogbS0xMTUuMiAzMDcuNzEyTDUxMiA0NDYuMTM4MTgybDExNS4yLTExNS4yYTQ2LjU0NTQ1NSA0Ni41NDU0NTUgMCAxIDEgNjUuODE1MjczIDY1Ljg2MTgxOEw1NzcuODYxODE4IDUxMmwxMTUuMiAxMTUuMmE0Ni41NDU0NTUgNDYuNTQ1NDU1IDAgMSAxLTY1Ljg2MTgxOCA2NS44MTUyNzNMNTEyIDU3Ny44NjE4MThsLTExNS4yIDExNS4yYTQ2LjU0NTQ1NSA0Ni41NDU0NTUgMCAxIDEtNjUuODE1MjczLTY1Ljg2MTgxOEw0NDYuMTM4MTgyIDUxMmwtMTE1LjItMTE1LjJhNDYuNTQ1NDU1IDQ2LjU0NTQ1NSAwIDEgMSA2NS44NjE4MTgtNjUuODE1MjczeiIgcC1pZD0iODY3NiIgZmlsbD0iIzcwNzA3MCI+PC9wYXRoPjwvc3ZnPg=="
              alt="关闭"/>
            <img onClick={handleRefreshEvent}
              src="data:image/svg+xml;base64,PHN2ZyB0PSIxNjI2NjE0NDk5NjM4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjEzNjAiIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIj48cGF0aCBkPSJNMTg3LjQ1NiA0MjUuMDI0YTMzNiAzMzYgMCAwIDAgMzY4LjM4NCA0MjAuMjI0IDQ4IDQ4IDAgMCAxIDEyLjU0NCA5NS4xNjggNDMyIDQzMiAwIDAgMS00NzMuNjY0LTU0MC4xNmwtNTcuMjgtMTUuMzZhMTIuOCAxMi44IDAgMCAxLTYuMjcyLTIwLjkyOGwxNTkuMTY4LTE3OS40NTZhMTIuOCAxMi44IDAgMCAxIDIyLjE0NCA1Ljg4OGw0OC4wNjQgMjM1LjA3MmExMi44IDEyLjggMCAwIDEtMTUuODA4IDE0LjkxMmwtNTcuMjgtMTUuMzZ6TTgzNi40OCA1OTkuMDRhMzM2IDMzNiAwIDAgMC0zNjguMzg0LTQyMC4yMjQgNDggNDggMCAxIDEtMTIuNTQ0LTk1LjE2OCA0MzIgNDMyIDAgMCAxIDQ3My42NjQgNTQwLjE2bDU3LjI4IDE1LjM2YTEyLjggMTIuOCAwIDAgMSA2LjI3MiAyMC45MjhsLTE1OS4xNjggMTc5LjQ1NmExMi44IDEyLjggMCAwIDEtMjIuMTQ0LTUuODg4bC00OC4wNjQtMjM1LjA3MmExMi44IDEyLjggMCAwIDEgMTUuODA4LTE0LjkxMmw1Ny4yOCAxNS4zNnoiIGZpbGw9IiM3MDcwNzAiIHAtaWQ9IjEzNjEiPjwvcGF0aD48L3N2Zz4="
              alt="刷新"/>
          </div>
          <div className="wg-cap-wrap__btn">
            <button onClick={handleConfirmEvent}>
              { formatMessage({ id: "btn.ok" }) }
            </button>
          </div>
        </div>
      </div>
      </div>
    </Fragment>)

}

export default ClickCaptcha
