import { type FC, PropsWithChildren } from 'react';
import { formatMessage } from 'react-intl-universal';

import "./container.css";

interface ContainerProps {
  title: string;
}

const Container: FC<PropsWithChildren<ContainerProps>> = ({ title, children }) => {
  
  return (
    <div className="pudu-onelogin-container">
      <h3 className='pudu-account-head'>
        <p>
          { formatMessage({id: 'title.login' })}
        </p>
        <p>
          { title }
        </p>
      </h3>
      {children}
    </div>
  )
}

export default Container;
