import { JSEncrypt } from "jsencrypt"

const PublicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDap1Y+XnULSQED/wRLQf5HxBKh
5GDLR0juEbG5GCQvOFagg1bfj9dQtCg+L/Lts/hvLZ5IwxB78g7Zzd9aVZDRr5ON
wXn/P9A9ZT15Mwt9oEsBbJwKRJg0D1GF9ENRGhpfxqvAzekI0hw7fakP47lviIXB
oX+Nv2MHsOjLrSY70wIDAQAB
-----END PUBLIC KEY-----`


export function encrypt(data: string): string {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(PublicKey);
  const encrypted = encrypt.encrypt(data);
  return encrypted
}
