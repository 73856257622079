import { type FC, useEffect } from 'react';
import { formatMessage } from 'react-intl-universal';
import IconCheck from '../assets/check.png'
import styles from './success.module.css'

const Success: FC = () => {
  return (
    <div className={styles.success}>
      <img className={styles.successImage} src={IconCheck} alt="check" />
      <p className={styles.successText}>
        { formatMessage({ id: 'tip.login.success' }) }
      </p>
    </div>
  )
}


export default Success;
