import { ReactNode, useMemo, useState, type FC } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import './input.css'

export interface InputProps {
  type: 'text' | 'password',
  placeholder: string;
  name: string;
  prefix?: ReactNode;
}


const Input: FC<InputProps> = ({ prefix, type, placeholder, name, ...restProps }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const inputType = useMemo(() => {
    if (type !== 'password') {
      return type;
    }

    if (passwordVisible) {
      return 'text';
    }
    
    return type;
  }, [type, passwordVisible]);

  return (
    <div className='pudu-input form-item' {...restProps}>
      {
        prefix && (
          <span className="pudu-input__prefix">
            { prefix }
          </span>
        )
      }
      <input
        type={inputType}
        className="pudu-input__inner"
        placeholder={placeholder}
        name={name}
        required
      />
      {
        type === 'password' && (
          <span
            className="pudu-input__suffix"
            onClick={() => setPasswordVisible(v => !v)}
          >
            { passwordVisible && <EyeInvisibleOutlined /> }
            { !passwordVisible && <EyeOutlined /> }
          </span>
        )
      }
    </div>
  )
}


export default Input;
