import React, { useEffect, useCallback, useState, useMemo } from 'react';
import intl from 'react-intl-universal';
import './App.css';
import AccoutLogin from './account-login'
import WeWorkLogin from './wework-login'

// @ts-ignore
import IconCover from '../assets/cover.png'
// @ts-ignore
import IconDesktop from '../assets/desktop.svg'
// @ts-ignore
import IconQrcode from '../assets/qrcode.svg'
import { getCurrentLocale, getLocaleData } from '../i18n'
import useQuery from '../hooks/use-query';
import { Messenger } from '../common/messenger';

enum LoginType {
  ACCOUNT,                      // 账号密码登录
  WEWORK                        //  企业微信扫码登录
}

function App() {
  const [loginType, setLoginType] = useState<LoginType>(LoginType.ACCOUNT);
  const [initDone, setInitDone] = useState(false);
  const query = useQuery();
  
  const title = useMemo(() => {
    return decodeURIComponent(query.get('title') ?? '');
  }, [query])
  
  useEffect(() => {
    const appId = query.get('appid') ?? '';
    if (window.sessionStorage) {
      window.sessionStorage.setItem('appid', appId);
    }
    
    Messenger.getInstance().init(appId);
    initializeIntl();
    setInitDone(true);
  }, []);

  const initializeIntl = () => {
    updateLocale();
  }

  const updateLocale = () => {
    const currentLocale = getCurrentLocale();
    
    intl.init({
      currentLocale,
      locales: getLocaleData()
    })
  }

  const switchLoginType = useCallback(() => {
    if (loginType === LoginType.ACCOUNT) {
      return setLoginType(LoginType.WEWORK);
    }
    setLoginType(LoginType.ACCOUNT);
  }, [loginType]);

  if (!initDone) {
    return null;
  }
  
  return (
    <div className="pudu-onelogin">
      <div className="pudu-onelogin-switcher" onClick={() => switchLoginType()}>
        <img className="cover" src={IconCover} alt="switcher covert"/>
        <img
            className="type"
            src={loginType === LoginType.ACCOUNT ? IconQrcode : IconDesktop}
            alt="login type"
          />
        </div>
      
      { loginType === LoginType.ACCOUNT && <AccoutLogin title={title} /> }
      { loginType === LoginType.WEWORK && <WeWorkLogin title={title} /> }
    </div>
  );
}

export default App;
