

type Command = 'init' | 'login_success' | 'login_failed' | 'logged_in' | 'password_forgotten'
type TransferData = {
  command: Command,
  appid: string,
  token?: string,
  expires?: number,
  error?: string,
}


export class Messenger {
  
  private static instance: Messenger | null = null;
  private parentOrigin: string = '';
  private appid: string = ''
  private inited: boolean = false

  private constructor() {
    
  }

  public static getInstance(): Messenger {
    if (Messenger.instance === null) {
      Messenger.instance = new Messenger()
    }
    return Messenger.instance;
  }

  init(appId: string) {
    if (this.inited) {
      return
    }
    
    this.appid = appId

    window.addEventListener('message',  (evt) => {
      const origin = evt.origin;
      const data = evt.data;
      if (data === 'init') {
        this.parentOrigin = origin
      }
    })

    this.inited = true;
  }

  notifyLoginSuccess(token: string, expires: number) {
    const message = new Map<keyof TransferData, any>();
    message.set('command', 'login_success');
    message.set('appid', this.appid);
    message.set('token', token);
    message.set('expires', expires);

    console.log("notifyLoginSuccess: origin: ", this.parentOrigin, " ;message: ", message);

    window.parent.postMessage(message, this.parentOrigin);
  }

  notifyLoginFailed(error: string) {
    const message = new Map();
    message.set('command', 'login_failed')
    message.set('appid', this.appid);
    message.set('error', error)

    window.parent.postMessage(message, this.parentOrigin);
  }

  notifyPasswordForgetten() {
    const message = new Map();
    message.set('command', 'password_forgotten');
    message.set('appid', this.appid);
    
    window.parent.postMessage(message, this.parentOrigin);
  }
  
}
