import { type FC } from 'react';
import { useSearchParams } from 'react-router-dom';


// 扫码登录的self redirect中使用, 此时window.parent就是one login页面
const Redirect: FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') ?? '';
  const platformId = searchParams.get('platformid') ?? ''
  console.log("in redirect compoent...")
  if (window.parent && window.parent.onCodeReceived) {
    console.log("Calling window.parent.onCodeReceived")
    // 通知上层获取code
    window.parent.onCodeReceived({ code, platformId });
  } else {
    console.warn("window.parent.onCodeReceived not received!")
  }

  return (
    <div>
      Redirecting...
    </div>
  )
}


export default Redirect;
