import ZhCNMessages from './zh-CN/message'
import EnUSMessages from './en-US/message'
import intl from 'react-intl-universal'


// locale = language-code + area
export const SUPPORT_LANGUAGES = [
  { label: 'Chinese', value: 'zh-CN', messages: ZhCNMessages },
  { label: 'English', value: 'en-US', messages: EnUSMessages },
]

export const DEFAULT_SUPPORT_LANGUAGE = SUPPORT_LANGUAGES.find(item => item.value === 'en-US')

export function getCurrentLocale(): string {
  let currentLocale = intl.determineLocale({
    urlLocaleKey: 'lang', // Example: https://fe-tool.com/react-intl-universal?lang=en-US
    cookieLocaleKey: 'lang', // Example: "lang=en-US" in cookie
  });

  if (!supportLocale(currentLocale)) {
    currentLocale = DEFAULT_SUPPORT_LANGUAGE!.value
  }

  return currentLocale;
}

export function supportLocale(locale: string): boolean {
  return SUPPORT_LANGUAGES.some(item => item.value === locale)
}


export function getLocaleData() {
  return SUPPORT_LANGUAGES.reduce((acc, item) => {
    acc[item.value] = item.messages;
    return acc;
  }, {} as Record<string, any>)
}

